import { types } from "./mutations";
import * as Api from "@/services/auth";
import route from "@/router.js"

function setLoading({ commit }, payload) {
    commit(types.SET_LOADING, payload);
}

async function getAllRoles({ commit }) {
    commit(types.SET_LOADING, true);
    try {
        const response = await Api.getAllRoles();
        const {
            data: { data },
        } = response;
        console.log('data', data)
        commit(types.SET_ALL_ROLES, data);
    } catch (err) {
        console.log(err);
    } finally {
        commit(types.SET_LOADING, false);
    }
}

async function getAllPermissions({ commit }) {
    commit(types.SET_LOADING, true);
    try {
        const response = await Api.getAllPermissions();
        // const {
        //     data: { data },
        // } = response;
        commit(types.SET_ALL_PERMISSIONS, response.data);
    } catch (err) {
        console.log(err);
    } finally {
        commit(types.SET_LOADING, false);
    }
}

async function getById({ commit }, payload) {
    commit(types.SET_LOADING, true);
    try {
        const response = await Api.getById(payload);
        const {
            data: { data },
        } = response;
        commit(types.SET_ONE, data);
    } catch (err) {
        console.log(err);
    } finally {
        commit(types.SET_LOADING, false);
    }
}

async function createItem({ commit, dispatch }, payload) {
    commit(types.SET_LOADING, true);
    try {
        const response = await Api.create(payload);
        const {
            // eslint-disable-next-line no-unused-vars
            data
        } = response;

        dispatch('notifications/setNotification', { message: 'تم الإضافة بنجاح', type: 'success' }, { root: true })

        route.push('/roles')
    } catch (err) {
        dispatch('notifications/setNotification', { message: 'حدثت مشكلة', type: 'error' }, { root: true })
    } finally {
        commit(types.SET_LOADING, false);
    }
}

async function updateItem({ commit, dispatch }, payload) {
    const { id, formData } = payload
    commit(types.SET_LOADING, true);
    try {
        const response = await Api.update(id, formData);
        // eslint-disable-next-line no-unused-vars
        const data = response.data
        dispatch('notifications/setNotification', { message: 'تم التعديل بنجاح', type: 'success' }, { root: true })
        route.push('/roles')
    } catch (err) {
        console.log(err);
    } finally {
        commit(types.SET_LOADING, false);
    }
}

async function deleteItem({ commit, dispatch, state }, id) {
    commit(types.SET_LOADING, true);

    try {
        const response = await Api.remove(id);
        // eslint-disable-next-line no-unused-vars
        const data = response.data
        dispatch('notifications/setNotification', { message: 'تم الحذف بنجاح ', type: 'success' }, { root: true })

        commit(types.SET_ALL, state.list.filter(item => item.id !== id));
        // route.push('/plans')
    } catch (err) {
        console.log(err);
    } finally {
        commit(types.SET_LOADING, false);
    }
}


export default { setLoading, getAllRoles, getAllPermissions, getById, createItem, updateItem, deleteItem };